import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { getFirstError as __unimport_getFirstError } from '/var/node/assets/js/utils/validation.ts';
import { unref as __unimport_unref_ } from 'vue';function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  "class": "p-6"
};
var _hoisted_2 = {
  "class": "p-4 mt-6 very-lightgrey-background rounded-corners"
};
var _hoisted_3 = {
  key: 0,
  "class": "my-2 info alert-info rounded-corners"
};
var _hoisted_4 = {
  "class": "p-4"
};
import { cloneDeep, isEqual } from 'lodash-es';
import TextField from '@/components/TextField.vue';
import Countries from '@/components/Countries.vue';
import Seat from '@/components/natPersonForm/Seat.vue';
import CompanySearchField from '@/components/servicesView/CompanySearchField.vue';

import { useWizardStep } from '/var/node/assets/js/composables/useWizardStep.ts';
import { computed, ref, watch } from 'vue';
import { useQuery } from '@tanstack/vue-query';
export default /*@__PURE__*/_defineComponent({
  __name: 'JurPersonFormCompanyStep',
  props: {
    errors: {}
  },
  emits: ['changed'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var emit = __emit;
    var _useWizardStep = useWizardStep(),
      data = _useWizardStep.data,
      save = _useWizardStep.save,
      isSaving = _useWizardStep.isSaving;
    var isForeign = computed(function () {
      return data.value.jurPerson.country !== 'CH';
    });
    var initialJurPerson = ref(cloneDeep(data.value.jurPerson));
    var selectedCompanyShabexId = ref(null);
    var _useQuery = useQuery({
        queryKey: [computed(function () {
          return "/api/company-search/companies/".concat(selectedCompanyShabexId.value);
        })],
        enabled: computed(function () {
          return selectedCompanyShabexId.value !== null;
        })
      }),
      selectedCompany = _useQuery.data;
    watch(selectedCompany, function (newValue) {
      if (newValue) {
        var _newValue$address$plz, _newValue$legalSeat, _newValue$address$cit, _newValue$kanton, _newValue$address$car;
        data.value.jurPerson = _objectSpread(_objectSpread({}, data.value.jurPerson), {}, {
          companyName: newValue.name,
          uid: newValue.uid,
          streetAddress: [newValue.address.street, newValue.address.housenumber].filter(function (i) {
            return typeof i === 'string';
          }).join(' '),
          seat: {
            plz: (_newValue$address$plz = newValue.address.plz) !== null && _newValue$address$plz !== void 0 ? _newValue$address$plz : undefined,
            sitz: (_newValue$legalSeat = newValue.legalSeat) !== null && _newValue$legalSeat !== void 0 ? _newValue$legalSeat : undefined,
            ort: (_newValue$address$cit = newValue.address.city) !== null && _newValue$address$cit !== void 0 ? _newValue$address$cit : undefined,
            kanton: (_newValue$kanton = newValue.kanton) !== null && _newValue$kanton !== void 0 ? _newValue$kanton : undefined
          },
          coAddress: (_newValue$address$car = newValue.address.careOf) !== null && _newValue$address$car !== void 0 ? _newValue$address$car : undefined
        });
      }
    });
    var companySelected = function companySelected(company) {
      if (company) {
        selectedCompanyShabexId.value = company.data;
        var companyReferenceId = Number(company.data);
        data.value.jurPerson.companyReferenceId = Number.isNaN(companyReferenceId) ? undefined : companyReferenceId;
        emit('changed', true);
      }
    };
    var isEdit = computed(function () {
      return !!data.value.jurPerson.id;
    });
    watch(data.value.jurPerson, function (newValue) {
      if (newValue) {
        emit('changed', !isEqual(initialJurPerson.value, newValue));
      }
    }, {
      deep: true,
      immediate: true
    });
    return function (_ctx, _cache) {
      var _ctx$errors, _unref$jurPerson, _unref$jurPerson$seat, _unref$jurPerson2, _ctx$errors2, _ctx$errors3, _ctx$errors4, _ctx$errors5, _ctx$errors6, _ctx$errors7, _ctx$errors8, _ctx$errors9;
      var _component_BusyButton = _resolveComponent("BusyButton");
      var _component_ModalFooter = _resolveComponent("ModalFooter");
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(Countries, {
        id: "nationality",
        modelValue: _unref(data).jurPerson.country,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _unref(data).jurPerson.country = $event;
        }),
        name: "nationality",
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('jur_person', 'fields.country'),
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors = _ctx.errors) === null || _ctx$errors === void 0 ? void 0 : _ctx$errors.country),
        disabled: _unref(isEdit)
      }, null, 8 /* PROPS */, ["modelValue", "label", "error", "disabled"]), _createElementVNode("div", _hoisted_2, [_unref(isForeign) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_cache[9] || (_cache[9] = _createElementVNode("i", {
        "class": "fal fa-info-circle"
      }, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('involved_person', 'form.foreign_company_info')), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true), !_unref(isForeign) ? (_openBlock(), _createBlock(CompanySearchField, {
        key: 1,
        "initial-company-name": [(_unref$jurPerson = _unref(data).jurPerson) === null || _unref$jurPerson === void 0 ? void 0 : _unref$jurPerson.companyName, (_unref$jurPerson$seat = _unref(data).jurPerson.seat) === null || _unref$jurPerson$seat === void 0 ? void 0 : _unref$jurPerson$seat.sitz, (_unref$jurPerson2 = _unref(data).jurPerson) === null || _unref$jurPerson2 === void 0 ? void 0 : _unref$jurPerson2.uid].filter(function (i) {
          return !!i;
        }).join(', '),
        disabled: _unref(isEdit),
        name: "companyNameSearch",
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('jur_person', 'fields.company_name'),
        onCompanySelected: companySelected
      }, null, 8 /* PROPS */, ["initial-company-name", "disabled", "label"])) : _createCommentVNode("v-if", true), _unref(isForeign) ? (_openBlock(), _createBlock(TextField, {
        key: 2,
        modelValue: _unref(data).jurPerson.companyName,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return _unref(data).jurPerson.companyName = $event;
        }),
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors2 = _ctx.errors) === null || _ctx$errors2 === void 0 ? void 0 : _ctx$errors2.companyName),
        disabled: _unref(isEdit),
        name: "companyName",
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('jur_person', 'fields.company_name')
      }, null, 8 /* PROPS */, ["modelValue", "error", "disabled", "label"])) : _createCommentVNode("v-if", true), _createVNode(TextField, {
        modelValue: _unref(data).jurPerson.uid,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return _unref(data).jurPerson.uid = $event;
        }),
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors3 = _ctx.errors) === null || _ctx$errors3 === void 0 ? void 0 : _ctx$errors3.uid),
        disabled: _unref(isEdit),
        name: "uid",
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('jur_person', 'fields.uid')
      }, null, 8 /* PROPS */, ["modelValue", "error", "disabled", "label"]), _createVNode(TextField, {
        modelValue: _unref(data).jurPerson.streetAddress,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return _unref(data).jurPerson.streetAddress = $event;
        }),
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors4 = _ctx.errors) === null || _ctx$errors4 === void 0 ? void 0 : _ctx$errors4.streetAddress),
        name: "streetAddress",
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('jur_person', 'fields.street_address'),
        placeholder: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'form.optional')
      }, null, 8 /* PROPS */, ["modelValue", "error", "label", "placeholder"]), _unref(isForeign) ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 3
      }, [_createVNode(TextField, {
        modelValue: _unref(data).jurPerson.seat.plz,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return _unref(data).jurPerson.seat.plz = $event;
        }),
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors5 = _ctx.errors) === null || _ctx$errors5 === void 0 || (_ctx$errors5 = _ctx$errors5.seat) === null || _ctx$errors5 === void 0 ? void 0 : _ctx$errors5.plz),
        name: "postalCode",
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('jur_person', 'fields.postal_code'),
        placeholder: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'form.optional')
      }, null, 8 /* PROPS */, ["modelValue", "error", "label", "placeholder"]), _createVNode(TextField, {
        modelValue: _unref(data).jurPerson.seat.sitz,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
          return _unref(data).jurPerson.seat.sitz = $event;
        }),
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors6 = _ctx.errors) === null || _ctx$errors6 === void 0 || (_ctx$errors6 = _ctx$errors6.seat) === null || _ctx$errors6 === void 0 ? void 0 : _ctx$errors6.sitz),
        name: "legalSeat",
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('jur_person', 'fields.address_locality')
      }, null, 8 /* PROPS */, ["modelValue", "error", "label"])], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true), !_unref(isForeign) ? (_openBlock(), _createBlock(Seat, {
        key: 4,
        modelValue: _unref(data).jurPerson.seat,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
          return _unref(data).jurPerson.seat = $event;
        }),
        "is-switzerland": true,
        errors: (_ctx$errors7 = _ctx.errors) === null || _ctx$errors7 === void 0 ? void 0 : _ctx$errors7.seat,
        "translation-domain": "jur_person"
      }, null, 8 /* PROPS */, ["modelValue", "errors"])) : _createCommentVNode("v-if", true), _createVNode(TextField, {
        modelValue: _unref(data).jurPerson.coAddress,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
          return _unref(data).jurPerson.coAddress = $event;
        }),
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors8 = _ctx.errors) === null || _ctx$errors8 === void 0 ? void 0 : _ctx$errors8.coAddress),
        name: "coAddress",
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('jur_person', 'fields.co_address'),
        placeholder: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'form.optional')
      }, null, 8 /* PROPS */, ["modelValue", "error", "label", "placeholder"]), _createVNode(TextField, {
        modelValue: _unref(data).jurPerson.telephoneNumber,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = function ($event) {
          return _unref(data).jurPerson.telephoneNumber = $event;
        }),
        error: ("getFirstError" in _ctx ? _ctx.getFirstError : __unimport_unref_(__unimport_getFirstError))((_ctx$errors9 = _ctx.errors) === null || _ctx$errors9 === void 0 ? void 0 : _ctx$errors9.telephoneNumber),
        name: "telephoneNumber",
        label: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('jur_person', 'fields.telephone_number'),
        placeholder: ("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'form.optional')
      }, null, 8 /* PROPS */, ["modelValue", "error", "label", "placeholder"])])]), _createVNode(_component_ModalFooter, null, {
        "default": _withCtx(function () {
          return [_createVNode(_component_BusyButton, {
            id: "jur-person-submit",
            name: "jurPersonSubmit",
            busy: _unref(isSaving),
            onClick: _unref(save)
          }, {
            "default": _withCtx(function () {
              return [_createTextVNode(_toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'form.save_and_next')), 1 /* TEXT */)];
            }),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["busy", "onClick"])];
        }),
        _: 1 /* STABLE */
      })]);
    };
  }
});