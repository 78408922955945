import { translate as __unimport_translate } from '/var/node/assets/js/utils/translate.ts';
import { unref as __unimport_unref_ } from 'vue';function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, isRef as _isRef, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 1
};
var _hoisted_2 = {
  "class": "mb-3"
};
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep, isEqual } from 'lodash-es';
import { useValidationErrors } from '@/js/composables/useValidationErrors';
import Nationality from '@/enums/Nationality';

import { toRefs, computed, unref, ref, watch } from 'vue';
import { useQuery, useMutation } from '@tanstack/vue-query';
import { useMutationFn } from '/var/node/assets/js/composables/useMutationFn.ts';
export default /*@__PURE__*/_defineComponent({
  __name: 'ProcessStepJurPersonSignerForm',
  props: {
    fileToken: {},
    jurPersonAtId: {},
    isESignature: {
      type: Boolean
    },
    jurPersonSignerAtId: {
      "default": null
    },
    eSignUrl: {}
  },
  emits: ['updated', 'back', 'changed'],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var emit = __emit;
    var _toRefs = toRefs(props),
      jurPersonSignerAtId = _toRefs.jurPersonSignerAtId,
      jurPersonAtId = _toRefs.jurPersonAtId;
    var isEdit = computed(function () {
      return unref(jurPersonSignerAtId) !== null;
    });
    // fetch data for existing person IF personId is set
    var _useQuery = useQuery({
        queryKey: [jurPersonSignerAtId],
        enabled: isEdit
      }),
      fetchedJurPersonSigner = _useQuery.data,
      isFetching = _useQuery.isFetching;
    var jurPersonSigner = ref({
      '@type': 'JurPersonSigner'
    });
    var newToken = uuidv4();
    var natPerson = ref({
      '@type': 'NatPerson',
      country: Nationality.SCHWEIZ,
      seat: {},
      origins: [{
        nationality: Nationality.SCHWEIZ
      }],
      token: newToken,
      initialFileToken: newToken
    });
    var initialNatPerson = ref(natPerson.value);
    watch(fetchedJurPersonSigner, function (value) {
      if (value) {
        var _clone$person;
        var clone = cloneDeep(value);
        jurPersonSigner.value = _objectSpread(_objectSpread({}, clone), {}, {
          person: undefined
        });
        natPerson.value = (_clone$person = clone.person) !== null && _clone$person !== void 0 ? _clone$person : natPerson.value;
        initialNatPerson.value = natPerson.value;
      }
    }, {
      immediate: true
    });
    var newMutationFn = useMutationFn('/api/jur_person_signers/create_or_merge', {
      method: 'POST'
    });
    var editMutationFn = useMutationFn(jurPersonSignerAtId, {
      method: 'PATCH'
    });
    // If it is an edit, we simply patch the JurPersonSigner
    // If it is a new person, we need to first create it and then inject it into the process step
    var resultingMutationFn = computed(function () {
      return unref(isEdit) ? editMutationFn : newMutationFn;
    });
    var _useMutation = useMutation({
        mutationFn: resultingMutationFn,
        onSuccess: function onSuccess(newValue) {
          emit('updated', newValue);
        }
      }),
      isPending = _useMutation.isPending,
      mutate = _useMutation.mutate,
      error = _useMutation.error;
    var _useValidationErrors = useValidationErrors(error),
      fieldErrors = _useValidationErrors.fieldErrors;
    function submit() {
      mutate({
        data: _objectSpread(_objectSpread({}, jurPersonSigner.value), {}, {
          person: natPerson.value,
          signingForJurPerson: unref(jurPersonAtId)
        })
      });
    }
    watch(natPerson, function (newValue) {
      if (newValue) {
        emit('changed', !isEqual(initialNatPerson.value, newValue));
      }
    }, {
      deep: true,
      immediate: true
    });
    return function (_ctx, _cache) {
      var _unref2, _ctx$isESignature;
      var _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
      var _component_NatPersonForm = _resolveComponent("NatPersonForm");
      var _component_JurPersonSignerForm = _resolveComponent("JurPersonSignerForm");
      var _component_BusyButton = _resolveComponent("BusyButton");
      var _component_ModalFooter = _resolveComponent("ModalFooter");
      return _unref(isFetching) ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
        key: 0
      })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_NatPersonForm, {
        modelValue: _unref(natPerson),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _isRef(natPerson) ? natPerson.value = $event : null;
        }),
        "file-token": _ctx.fileToken,
        errors: (_unref2 = _unref(fieldErrors)) === null || _unref2 === void 0 ? void 0 : _unref2.person,
        "is-e-signature": (_ctx$isESignature = _ctx.isESignature) !== null && _ctx$isESignature !== void 0 ? _ctx$isESignature : false,
        "e-sign-url": _ctx.eSignUrl
      }, null, 8 /* PROPS */, ["modelValue", "file-token", "errors", "is-e-signature", "e-sign-url"]), _cache[4] || (_cache[4] = _createElementVNode("hr", {
        "class": "m-0"
      }, null, -1 /* HOISTED */)), _createVNode(_component_JurPersonSignerForm, {
        modelValue: _unref(jurPersonSigner),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return _isRef(jurPersonSigner) ? jurPersonSigner.value = $event : null;
        }),
        errors: _unref(fieldErrors)
      }, null, 8 /* PROPS */, ["modelValue", "errors"]), _createVNode(_component_ModalFooter, {
        "class": "justify-between"
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("button", {
            type: "button",
            "class": "btn btn-secondary mb-3",
            onClick: _cache[2] || (_cache[2] = function () {
              return emit('back');
            })
          }, [_cache[3] || (_cache[3] = _createElementVNode("i", {
            "class": "far fa-arrow-left"
          }, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'action.back')), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_BusyButton, {
            id: "jur-person-signer-submit",
            name: "jurPersonSignerSubmit",
            busy: _unref(isPending),
            onClick: submit
          }, {
            "default": _withCtx(function () {
              return [_createTextVNode(_toDisplayString(("translate" in _ctx ? _ctx.translate : __unimport_unref_(__unimport_translate))('messages', 'form.save')), 1 /* TEXT */)];
            }),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["busy"])])];
        }),
        _: 1 /* STABLE */
      })]));
    };
  }
});